import React, { useEffect, useState } from 'react'
import { Heading, Box, VStack } from '@chakra-ui/react'
import Input from '../../../../molecules/controls/Input'
import Select from '../../../../molecules/controls/Select'
import { useFormContext } from 'react-hook-form'
import useFormState from '../../../../../hooks/useFormState'
import { eventTypes } from '@vidday/data'
import Card from '../../../../atoms/Card'
import { updateOccasion, updateOccasionOther } from '../../../../../../reducers/forms/start-a-vidday'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import CheckExMark from '../../../../atoms/CheckExMark'
import { showOtherFieldFor, showYearsFieldFor } from '../../../EventDetails/schema'

const StepOne = () => {
	/** Retrieve search query parameters */
	const [searchQuery] = useSearchParams()

	/** Retrieve form state from react-hook-form */
	const { control, errors, setValue, watch, register } = useFormContext()

	/** Redux dispatch */
	const dispatch = useDispatch()

	/** Retrieve values from form's redux */
	const {
		data: { occasion, occasionOther, years },
	} = useFormState('NewVidday')

	/** Define state based on Redux values */
	const [showYearField, setShowYearField] = useState(showYearsFieldFor.includes(occasion))
	const [showOtherField, setShowOtherField] = useState(showOtherFieldFor.includes(occasion))

	/** Log tracking event for the form progress (STEP 1) */
	const [trackingSent, setTrackingSent] = useState(false)

	/** Watch values from react-hook-form */
	const values = watch()

	/** Prepopulate form values for this step, based on existing values (wizzard form) */
	useEffect(() => setValue('occasion', occasion), [occasion])
	useEffect(() => setValue('occasionOther', occasionOther), [occasionOther, showOtherField])
	useEffect(() => setValue('years', years), [years, showYearField])

	useEffect(() => {
		/** Should we display aditional fields ? */
		setShowYearField(showYearsFieldFor.includes(values.occasion))
		setShowOtherField(showOtherFieldFor.includes(values.occasion))

		if (showYearsFieldFor.includes(values.occasion)) {
			register({ name: 'years' })
		}
		if (showOtherFieldFor.includes(values.occasion)) {
			register({ name: 'occasionOther' })
		}

		/** Should we trigger tracking? */
		if (!trackingSent && values.occasion != '') {
			setTrackingSent(true)
		}
	}, [values.occasion])

	/** Set default occasion based on URL Query Params if any */
	useEffect(() => {
		const occasionParam = searchQuery.get('occasion')
		if (occasionParam) {
			const val = eventTypes.find((el) => el.type === occasionParam)
			if (val && val.type) {
				dispatch(updateOccasion(val.type))
				setValue('occasion', val.type)
				setShowYearField(showYearsFieldFor.includes(val.type))
				setShowOtherField(showOtherFieldFor.includes(val.type))

				// If we need to show the 'other' and also provided an 'occasion'
				if (showOtherFieldFor.includes(val.type) && searchQuery.has('occasion_other')) {
					dispatch(updateOccasionOther(searchQuery.get('occasion_other')))
					setValue('occasionOther', searchQuery.get('occasion_other'))
				}
			}
		}
	}, [searchQuery])

	const admissibleTypes = eventTypes.map((el, i) => {
		if (el.public && el.webApp) {
			return (
				<option key={i} value={el.type}>
					{el.emoji} {el.label}
				</option>
			)
		}
	})

	return (
		<>
			<Card
				px={['1rem', '2rem']}
				py="2rem"
				alignItems="center"
				variant="main"
				mb="2rem"
				textAlign="center"
				mx={['1rem', 0]}
				borderRadius={['xl']}
				pos="relative"
				overflow="visible">
				{values.occasion && (showOtherField ? values?.occasionOther : true) && (
					<Box
						mx="auto"
						mb="0.5rem"
						pos="absolute"
						transform="translateX(-50%)"
						left="50%"
						top="-1rem"
						padding="0.5rem"
						borderRadius="full"
						background="white">
						<CheckExMark render={true} check={true} scale={0.2} />
					</Box>
				)}

				<VStack spacing="1rem" w="full">
					<Heading size="md" as="h2">
						What's the occasion?
					</Heading>
					<Select
						control={control}
						errors={errors}
						name="occasion"
						placeholder="We're celebrating…"
						options={admissibleTypes}
					/>

					{showOtherField && (
						<Input
							control={control}
							errors={errors}
							defaultValue={''}
							name="occasionOther"
							placeholder="What's the special occasion?"
						/>
					)}

					{showYearField && (
						<Input
							control={control}
							errors={errors}
							defaultValue={''}
							name="years"
							placeholder="Celebrating how many years? (optional)"
						/>
					)}
				</VStack>
			</Card>
		</>
	)
}

export default StepOne
